import React from "react"
import { connect as connectFela } from "react-fela"
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat';

import Breadcrumb from "components/Breadcrumb"
import ArticleCard from "components/ArticleCard"
import Container from "components/container"
import H1 from "components/typography/H1"
import P from "components/typography/P"
import calc from "utilities/calc"
import colors from "theme/colors"
import fluidValues from "utilities/fluidValues"

dayjs.extend(customParseFormat);

const eventCard = ({rules, styles, article, odd}) => (
  <Container flex noPadding extend={{styles: rules.styles}}>
    <Container fullscreen flex extend={{styles: rules.subContainer}}>
    <div className={styles.circle} />
    <H1 style={'statement'} extend={{styles: rules.mobileDateDetails}}>
      {dayjs(article.eventstartdate).format('MMM\nDD')}
    </H1>

    <div className={styles.eventDetails}>
      <Breadcrumb extend={{styles: rules.breadcrumbs}} leftPart={'fake'} /> {/* this exists just to align the article title with the dateDetails */}
      <H1 style={'statement'} extend={{styles: rules.dateDetails}}>
        {dayjs(article.eventstartdate).format('MMM\nDD')}
      </H1>
      <P version={'info'}>{article.eventdescription}</P>
      <P version={'info'}>
        {dayjs(article.eventstartdate).format('hh:mm')}-{dayjs(article.eventenddate).format('hh:mm')} on {dayjs(article.eventenddate).format('DD MMM YYYY')}
      </P>
      <P version={'info'}>{article.eventlocation}</P>
    </div>
    <ArticleCard
      extend={{styles: rules.article}}
      title={article.title}
      introduction={'with'}
      extraAuthor={article.eventhost}
      cardIntro={article.cardintro}
      articleIntro={article.intro}
      excerpt={article.excerpt}
      articleType={article.articleType}
      cardTitle={article.cardtitle}
      linkBgColor={odd ? colors.colorHuman700 : colors.colorData700 }
      slug={article.slug}>
      <div className={styles.mobileEventDetails}>
        <P version={'info'}>{article.eventdescription}</P>
        <P version={'info'}>
          {dayjs(article.eventstartdate).format('hh:mm')}-{dayjs(article.eventenddate).format('hh:mm')} on {dayjs(article.eventenddate).format('DD MMM YYYY')}
        </P>
        <P version={'info'}>{article.eventlocation}</P>
      </div>
    </ArticleCard>
      </Container>
  </Container>
)

/*
 */
const styles = (props) => ({
  styles: {
    extend: {
      condition: props.odd,
      style: {
        justifyContent: 'flex-end'
      }
    }
  },
  subContainer: {
    ...fluidValues({paddingTop: [48, 105]}),
    display: 'flex',
    width: 'auto',
    position: 'relative',
    marginLeft: 0,
    marginRight: 0,
  },
  breadcrumbs: {
    visibility: 'hidden',
  },
  circle: {
    backgroundColor: colors.colorData300,
    borderRadius: '50%',
    height: '76vw',
    left: '-23vw',
    position: 'absolute',
    right: 0,
    top: 0,
    width: '76vw',
    zIndex: 1,
    maxWidth: 620,
    maxHeight: 620,

    tabletPortraitAndUp: {
      left: 0,
      width: '50vw',
      height: '50vw',
    },
    extend: {
      condition: props.odd,
      style: {
        backgroundColor: colors.colorHuman300,
      }
    }
  },
  mobileDateDetails: {
    paddingLeft: 20,
    paddingRight: 20,
    textAlign: 'left',
    width: '100%',
    zIndex: 2,
    marginBottom: '24px !important',

    tabletPortraitAndUp: {
      display: 'none',
    }
  },
  dateDetails: {
    phoneOnly: {
      display: 'none',
    }
  },
  mobileEventDetails: {
    marginTop: -8,
    order: 2,
    paddingBottom: 12,
    textAlign: 'left',
    zIndex: 2,

    tabletPortraitAndUp: {
      display: 'none',
    }
  },
  eventDetails: {
    display: 'none',
    textAlign: 'right',
    zIndex: 2,
    maxWidth: 405,
    width: '50%',
    order: 1,

    tabletPortraitAndUp: {
      display: 'block',
      boxSizing: 'border-box',
      paddingRight: calc(12, 45, 600, 1440),
      paddingLeft: calc(24, 76, 600, 1440),

      '@media(min-width: 1440px)': {
        paddingRight: 45,
        paddingLeft: 76,
      }
    }
  },
  article: {
    flex: '1 1 100%',
    order: 1,
    zIndex: 2,

    tabletPortraitAndUp: {
      boxSizing: 'border-box',
      flex: '1 1 50%',
      maxWidth: '405',
      minWidth: 'unset !important',
      order: 2,
      paddingLeft: calc(12, 45, 600, 1440),
      paddingRight: 0,

      '@media(min-width: 1440px)': {
        paddingLeft: 45,
      }
    }
  },
})

export default connectFela(styles)(eventCard)
