import React from "react"
import { connect as connectFela } from "react-fela"

import ArticleCard from "components/ArticleCard"
import Container from "components/container"
import fluidValues from "utilities/fluidValues"
import imageUrlFor from "utilities/imageUrlFor"

const ArticleImage = ({image, className}) => {
  return <div className={className}>
    <div style={{width: '100%', paddingBottom: (100/(843/633)) + "%"}}>

    </div>
    <img alt={''} src={imageUrlFor(image).width(843).height(633).url()} />
  </div>
}

const bigArticleCard = ({rules, styles, article}) => (
  <Container flex noPadding extend={{styles: rules.styles}}>
    <ArticleCard
      extend={{styles: rules.article}}
      title={article.title}
      author={article.author}
      published={article.published}
      cardIntro={article.cardintro}
      articleIntro={article._rawIntro}
      excerpt={article.excerpt}
      articleType={article.articleType}
      cardTitle={article.cardtitle}
      slug={article.slug} />

    {(article.featuredimage || article.image) && <ArticleImage className={styles.image} image={article.featuredimage || article.image} />}
    {/* <Img className={styles.image} image={(article.featuredimage && article.featuredimage.asset.gatsbyImageData) || (article.image && article.image.asset.fluid)} /> */}
  </Container>
)

/*
 */
const styles = (props) => ({
  styles: {},
  article: {
    flex: '1 1 100%',
    maxWidth: '100%',
    order: 2,
    ...fluidValues({padding: [20, 59]}),

    tabletLandscapeAndUp: {
      flex: '0 0 auto',
      maxWidth: 'unset',
      ...fluidValues({paddingRight: [25, 25], paddingLeft: [0, 0], paddingTop: [0, 0], paddingBottom: [0, 0]}),
      order: 1,
    }
  },
  image: {
    flex: '1 1 auto',
    position: 'relative',

    tabletLandscapeAndUp: {
      order: 2,
    },
    '& > img': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'center center',
      opacity: 1,
      transition: 'opacity 500ms ease 0s',
    }
  }
})

export default connectFela(styles)(bigArticleCard)
