const slugify = text => {
  if (!text) {
    return ""
  }

  const slug = text
    .trim()
    .replace(/&/g, "and")
    .replace(/[^A-Za-z0-9\-_]+/g, "-") // Replace consecutive non-alphanumeric characters with a single hyphen
    .replace(/--+/g, "-") // Replace multiple consecutive hyphens with a single hyphen
    .toLowerCase()

  return slug
}
export default slugify
